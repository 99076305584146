import React from 'react'

import PostCard from '../components/PostCard'
import './PostSection.css'

class PostSection extends React.Component {
  static defaultProps = {
    posts: [],
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { posts, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visiblePosts = posts.slice(0, limit || posts.length)

    return (
      <div className="wide projects">
        <div className="item-list third flex">
          {visiblePosts.map((post, index) => (
            <PostCard key={post.title + index} {...post} />
          ))}
        </div>

        {showLoadMore && visiblePosts.length < posts.length && (
          <button className="button" onClick={this.increaseLimit}>
            {loadMoreTitle}
          </button>
        )}
      </div>
    )
  }
}

export default PostSection
